<template lang="html">

  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow">
    <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
      Sign in to your account
    </h2>
    <!--<p class="mt-2 text-center text-sm text-gray-600 max-w">
      Or
      <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
        start your 14-day free trial
      </a>
    </p>-->
  </div>

  <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <form class="space-y-6" action="#" method="POST" @submit.prevent="sendForm">
        <div>
          <label for="email" class="block text-sm font-medium text-gray-700">
            Name
          </label>
          <div class="mt-1">
            <input class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" data-cy="name" type="text" v-model="form.name">
          </div>

          <has-error :form="form" field="name"/>
         
        </div>

        <div>
          <label for="email" class="block text-sm font-medium text-gray-700">
            Email
          </label>
          <div class="mt-1">
            <input class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" data-cy="name" type="email" v-model="form.email">
          </div>

          <has-error :form="form" field="email"/>
         
        </div>

        <div>
          <label for="password" class="block text-sm font-medium text-gray-700">
            Password
          </label>
          <div class="mt-1">
            <input id="password" v-model="form.password" name="password" type="password" autocomplete="current-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
           <has-error :form="form" field="email"/>
          </div>
            
        </div>

        

        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <input id="remember_me" name="remember_me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">
            <label for="remember_me" class="ml-2 block text-sm text-gray-900">
              Remember me
            </label>
          </div>

         
        </div>

        <div>
          <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Sign in
          </button>
        </div>
      </form>

    </div>
  </div>
</div>


</template>

<script>
import Cookie from 'js-cookie'
import { Form, HasError } from 'vform'
export default {
  name: 'login',
  components: {HasError},
  created(){
    console.log(process.env.VUE_APP_AUTH_COOKIE_NAME);
  },
  data(){
    return {
      form : new Form({
        name: '',
        email: '',
        password: ''
      })
    }
  },
  methods: {
    sendForm(){
      this.form.post('/register').then((response)=>{
          Cookie.set(process.env.VUE_APP_AUTH_COOKIE_NAME, response.data.api_token);
          this.$store.state.user = response.data;
          this.$router.push({name:'Dashboard'});
      }).catch((error)=>{
          if (error.response.status == 401) {
              alert('Invalid credentials');
          }
      })
    }
  }
}
</script>

<style lang="less">

.form{
  .form-group{
    label{
      display:block;
    }
    input[type="text"],input[type="email"],input[type="password"]{
      width:100%;
    }
  }
}
</style>
